<template>
  <b-container>
    <calculator-detail title="Time Value of Money" description="">
      <div slot="calculator" class="mt-4">
        <b-form-group label-cols-sm="3" label="Present Value">
          <b-input-group prepend="Rp" type="number">
            <b-form-input v-model="presentValue" v-currency></b-form-input>
            <b-input-group-append>
              <b-button
                variant="danger"
                class="calc-button"
                @click="calculatePresentValue"
                >Solve PV</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="3" label="Payments">
          <b-input-group prepend="Rp" type="number">
            <b-form-input v-model="payments" v-currency></b-form-input>
            <b-input-group-append>
              <b-button
                variant="danger"
                class="calc-button"
                @click="calculatePayments"
                >Solve PMT</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="3" label="Future Value">
          <b-input-group prepend="Rp">
            <b-form-input v-model="futureValue" v-currency></b-form-input>
            <b-input-group-append>
              <b-button
                variant="danger"
                class="calc-button"
                @click="calculateFutureValue"
                >Solve FV</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="3" label="Annual Rate">
          <b-input-group prepend="%" type="number">
            <b-form-input v-model="annualRate"></b-form-input>
            <b-input-group-append>
              <b-button
                variant="danger"
                class="calc-button"
                @click="calculateAnnualRate"
                >Solve Rate</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="3" label="Periods">
          <b-input-group type="number">
            <b-form-input v-model="periods"></b-form-input>
            <b-input-group-append>
              <b-button
                variant="danger"
                class="calc-button"
                @click="calculatePeriods"
                >Solve Period</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="3"
          label="Mode"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            class="pt-2"
            v-model="config.mode"
            :options="modeRadioOptions"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="3"
          label="Decimal Digit"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            class="pt-2"
            v-model="config.decimal"
            :options="decimalRadioOptions"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-button @click="clearForm" variant="danger">Reset</b-button>
      </div>
    </calculator-detail>
  </b-container>
</template>

<script>
import CalculatorDetail from '../../components/CalculatorDetail.vue'
import * as formulajs from '@formulajs/formulajs'

export default {
  name: 'TimeValueOfMoney',
  components: {
    CalculatorDetail,
  },
  data() {
    return {
      decimalRadioOptions: [
        { text: 'Two', value: 2 },
        { text: 'Three', value: 3 },
        { text: 'Four', value: 4 },
        { text: 'Five', value: 5 },
      ],
      modeRadioOptions: [
        {
          text: 'End',
          value: 0,
        },
        { text: 'Beginning', value: 1 },
      ],
      config: {
        percentFactor: 100,
        mode: 0,
        decimal: 2,
      },
      presentValue: '',
      payments: '',
      futureValue: '',
      annualRate: '',
      periods: '',
    }
  },
  computed: {
    pv() {
      return this.toZeroMinus(this.unformatCurrency(this.presentValue))
    },
    fv() {
      return this.toZeroMinus(this.unformatCurrency(this.futureValue))
    },
    pmt() {
      return this.toZeroMinus(this.unformatCurrency(this.payments))
    },
    r() {
      return parseFloat(this.annualRate) / 100
    },
    n() {
      return parseFloat(this.periods)
    },
  },
  methods: {
    toFixedDecimal(value) {
      if (isNaN(value)) return NaN

      return value.toFixed(this.config.decimal)
    },
    validateInput(v1, v2, v3) {
      if (isNaN(v1) || isNaN(v2) || isNaN(v3)) {
        alert('Harap isi semua nilai')
        return
      }
    },
    isPresent(value) {
      return !this.isEmpty(value) && value != 0
    },
    clearForm() {
      this.presentValue = ''
      this.payments = ''
      this.futureValue = ''
      this.annualRate = ''
      this.periods = ''
      this.config.mode = 'End'
      this.config.decimal = 2
    },
    calculatePresentValue() {
      const pv = formulajs.PV(
        this.r,
        this.n,
        this.pmt,
        this.fv,
        this.config.mode
      )

      this.presentValue = this.formatToCurrency(this.toFixedDecimal(pv))
    },
    calculatePayments() {
      const pmt = formulajs.PMT(
        this.r,
        this.n,
        this.pv,
        this.fv,
        this.config.mode
      )

      this.payments = this.formatToCurrency(this.toFixedDecimal(pmt))
    },
    calculateFutureValue() {
      const fv = formulajs.FV(
        this.r,
        this.n,
        this.pmt,
        this.pv,
        this.config.mode
      )
      this.futureValue = this.formatToCurrency(this.toFixedDecimal(fv))
    },
    calculateAnnualRate() {
      const rate = formulajs.RATE(
        this.n,
        this.pmt,
        this.pv,
        this.fv,
        this.config.mode,
        0.1
      )

      this.annualRate = this.toFixedDecimal(rate * 100)
    },
    calculatePeriods() {
      const n = formulajs.NPER(
        this.r,
        this.pmt,
        this.pv,
        this.fv,
        this.config.mode
      )
      this.periods = this.toFixedDecimal(n)
    },
  },
}
</script>
